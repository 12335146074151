import request from '@http';

// 2023/05/10 王珂 物业管理=》报修管理
export function repairManagement(params) {
    return request({
        method: 'get',
        url: `/api/repair/repairApplyList`,
        params,
    });
}
// 2023/5/11 王珂 报修记录附件
export function repairInfo(params) {
    return request({
        method: 'get',
        url: `/api/repair/repairApplyImageList`,
        params,
    });
}